<template>
    <!-- Header -->
    <div class="header">

        <!-- Logo -->
        <div class="header-left active">
            <router-link to="/dashboard" class="logo logo-normal">
                <img src="@/assets/img/logo/logo.svg" alt="">
            </router-link>
            <router-link to="/dashboard" class="logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="">
            </router-link>
            <router-link to="/dashboard" class="logo-small">
                <img src="@/assets/img/logo-small.png" alt="">
            </router-link>
            <a id="toggle_btn" href="javascript:void(0);" @click="toggleSidebar">
                <vue-feather type="chevrons-left"></vue-feather>
            </a>
        </div>
        <!-- /Logo -->

        <a id="mobile_btn" class="mobile_btn" href="javascript:void(0);" @click="toggleSidebar1">
            <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </a>

        <!-- Header Menu -->
        <ul class="nav user-menu">

            <!-- Search -->
            <li class="nav-item nav-searchinputs">
            </li>
            <!-- /Search -->


            <!-- Select Store -->
            <li class="nav-item dropdown has-arrow main-drop select-store-dropdown">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link select-store" data-bs-toggle="dropdown">
                    <span class="user-info">
                        <span class="user-letter">
                            <img src="@/assets/img/store/store-01.png" alt="Store Logo" class="img-fluid">
                        </span>
                        <span class="user-detail">
                            <span class="user-name">Select Store</span>
                        </span>
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="@/assets/img/store/store-01.png" alt="Store Logo" class="img-fluid"> Grocery Alpha
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="@/assets/img/store/store-02.png" alt="Store Logo" class="img-fluid"> Grocery Apex
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="@/assets/img/store/store-03.png" alt="Store Logo" class="img-fluid"> Grocery Bevy
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item">
                        <img src="@/assets/img/store/store-04.png" alt="Store Logo" class="img-fluid"> Grocery Eden
                    </a>
                </div>
            </li>
            <!-- /Select Store -->

            <li class="nav-item nav-item-box">
                <a href="javascript:void(0);" id="btnFullscreen" @click="initFullScreen">
                    <vue-feather type="maximize"></vue-feather>
                </a>
            </li>

            <!-- Notifications -->
            <li class="nav-item dropdown nav-item-box">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                    <vue-feather type="bell"></vue-feather><span class="badge rounded-pill">2</span>
                </a>
                <div class="dropdown-menu notifications">
                    <div class="topnav-dropdown-header">
                        <span class="notification-title">Notifications</span>
                        <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                    </div>
                    <div class="noti-content">
                        <ul class="notification-list">
                            <li class="notification-message">
                                <router-link to="/dashboard">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-02.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">John Doe</span> added
                                                new task <span class="noti-title">Patient appointment booking</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">4 mins ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/dashboard">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-03.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Tarah Shropshire</span>
                                                changed the task name <span class="noti-title">Appointment booking
                                                    with payment gateway</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">6 mins ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/dashboard">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-06.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Misty Tison</span>
                                                added <span class="noti-title">Domenic Houston</span> and <span
                                                    class="noti-title">Claire Mapes</span> to project <span
                                                    class="noti-title">Doctor available module</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">8 mins ago</span>
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/dashboard">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-17.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Rolland Webber</span>
                                                completed task <span class="noti-title">Patient and Doctor video
                                                    conferencing</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">12 mins ago</span></p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li class="notification-message">
                                <router-link to="/dashboard">
                                    <div class="media d-flex">
                                        <span class="avatar flex-shrink-0">
                                            <img alt="" src="@/assets/img/profiles/avatar-13.jpg">
                                        </span>
                                        <div class="media-body flex-grow-1">
                                            <p class="noti-details"><span class="noti-title">Bernardo Galaviz</span>
                                                added new task <span class="noti-title">Private chat module</span>
                                            </p>
                                            <p class="noti-time"><span class="notification-time">2 days ago</span></p>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="topnav-dropdown-footer">
                        <router-link to="/dashboard">View all Notifications</router-link>
                    </div>
                </div>
            </li>
            <!-- /Notifications -->

            <li class="nav-item dropdown has-arrow main-drop">
                <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                    <span class="user-info">
                        <span class="user-letter">
                            <img src="@/assets/img/profiles/avator1.jpg" alt="" class="img-fluid">
                        </span>
                        <span class="user-detail">
                            <span class="user-name">John Smilga</span>
                            <span class="user-role">Super Admin</span>
                        </span>
                    </span>
                </a>
                <div class="dropdown-menu menu-drop-user">
                    <div class="profilename">
                        <div class="profileset">
                            <span class="user-img"><img src="@/assets/img/profiles/avator1.jpg" alt="">
                                <span class="status online"></span></span>
                            <div class="profilesets">
                                <h6>John Smilga</h6>
                                <h5>Super Admin</h5>
                            </div>
                        </div>
                        <hr class="m-0">
                        <router-link class="dropdown-item" to="/profile"> <vue-feather class="me-2"
                                type="user"></vue-feather> My
                            Profile</router-link>
                        <router-link class="dropdown-item" to="/settings/general-settings"><vue-feather class="me-2"
                                type="settings"></vue-feather>Settings</router-link>
                        <router-link class="dropdown-item" to="/subscribe"><vue-feather class="me-2"
                                type="dollar-sign"></vue-feather>Subcribe</router-link>
                        <hr class="m-0">
                        <span class="dropdown-item logout pb-0" @click="logout()"><img src="@/assets/img/icons/log-out.svg"
                                class="me-2" alt="img">Logout</span>
                    </div>
                </div>
            </li>
        </ul>
        <!-- /Header Menu -->

        <!-- Mobile Menu -->
        <div class="dropdown mobile-user-menu">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
            <div class="dropdown-menu dropdown-menu-right">
                <router-link class="dropdown-item" to="/profile">My Profile</router-link>
                <router-link class="dropdown-item" to="/settings/general-settings">Settings</router-link>
                <span class="dropdown-item" @click="logout()">Logout</span>
            </div>
        </div>
        <!-- /Mobile Menu -->
    </div>
    <!-- /Header -->

    <side-settings></side-settings>
</template>

<script>
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from '@/components/modal/toast-message/toastMessage'

export default {
    data() {
        return {};
    },
    mounted() {
        this.initMouseoverListener();
    },
    methods: {
        toggleSidebar1() {
            const body = document.body;
            body.classList.toggle("slide-nav");
        },
        toggleSidebar() {
            const body = document.body;
            body.classList.toggle("mini-sidebar");
        },
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },

        initMouseoverListener() {
            document.addEventListener('mouseover', this.handleMouseover);
        },
        handleMouseover(e) {
            e.stopPropagation();

            const body = document.body;
            const toggleBtn = document.getElementById('toggle_btn');

            if (body.classList.contains('mini-sidebar') && this.isElementVisible(toggleBtn)) {
                const target = e.target.closest('.sidebar, .header-left');

                if (target) {
                    body.classList.add('expand-menu');
                    this.slideDownSubmenu();
                } else {
                    body.classList.remove('expand-menu');
                    this.slideUpSubmenu();
                }

                e.preventDefault();
            }
        },
        isElementVisible(element) {
            return element.offsetWidth > 0 || element.offsetHeight > 0;
        },
        slideDownSubmenu() {
            const subdropPlusUl = document.getElementsByClassName('subdrop');
            for (let i = 0; i < subdropPlusUl.length; i++) {
                const submenu = subdropPlusUl[i].nextElementSibling;
                if (submenu && submenu.tagName.toLowerCase() === 'ul') {
                    submenu.style.display = 'block';
                }
            }
        },
        slideUpSubmenu() {
            const subdropPlusUl = document.getElementsByClassName('subdrop');
            for (let i = 0; i < subdropPlusUl.length; i++) {
                const submenu = subdropPlusUl[i].nextElementSibling;
                if (submenu && submenu.tagName.toLowerCase() === 'ul') {
                    submenu.style.display = 'none';
                }
            }
        },
        logout() {
        const url = '/api/v1/auth/logout'
        const body = {
            refresh_token: JSON.parse(localStorage.getItem('auth')).refresh_token
        }

        axiosWithAuth.post(url, body)
        .then(response => {
          if (response.data.meta.code === 200) {
            localStorage.clear()
            this.$router.push("/login")
          }
        })
        .catch(() => {
          toast('error')  
        })
    },
    },
    beforeUnmount() {
        document.removeEventListener('mouseover', this.handleMouseover);
    },
};
</script>