<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Menunggu Pembayaran</h4>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="mb-5 px-5">
          <div class="card-body justify-content-center d-flex">
            <div class="border border-2 rounded-4" style="width: 500px">
              <a-skeleton v-if="isLoading" active />
              <div v-else class="p-4 rounded-top-4" style="background-color: #f0eef6">
                <div class="text-center fs-30 fw-bold">{{ formatIDR(subscription.pending_payment ? subscription.pending_payment.amount : 100000, false) }}</div>
                <div class="mt-5">
                  <strong class="fs-20">Detail pembayaran</strong>
                  <div class="d-flex justify-content-between mt-4">
                    <div class="text-start">
                      <div class="mb-2 fs-17">Nama Paket</div>
                      <div class="mb-2 fs-17">Status</div>
                      <div class="mb-2 fs-17">Tanggal Pembayaran</div>
                    </div>
                    <div class="text-end">
                      <div class="d-flex mb-2 justify-content-end">
                        <img src="@/assets/img/icon/icon-plus-plan.svg" style="width: 20px" alt="">
                        <div class="ms-1 fs-17">{{ subscription.plan_name }}</div>
                      </div>
                      <div class="mb-2 fs-17">{{ subscription.status }}</div>
                      <div class="mb-2 fs-17">{{ subscription.expired_at }}</div>
                    </div>
                  </div>
                </div>
                <div class="my-5">
                  <a-divider />
                </div>
                <div class="text-center">Selesaikan pembayaran anda untuk melanjutkan penggunaan akun Kasirkita</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center gap-5 my-10">
          <button class="btn btn-secondary rounded rounded-pill">
            Saya Sudah Bayar
          </button>
          <button class="btn btn-primary rounded rounded-pill">
            Bayar Sekarang
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatIDR } from "@/utils/libs";
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  data() {
    return {
      formatIDR,
      isLoading: true,
      subscription: null
    };
  },
  mounted() {
    this.getSubscription()
  },
  methods: {
    getSubscription() {
      const url = "/api/v1/subscription";
        axiosWithAuth
          .get(url)
          .then((response) => {
            this.isLoading = false;
            if (response.data.meta.code === 200) {
              this.subscription = response.data.data
            }
          })
          .catch(() => {
            toast("error");
            this.isLoading = false;
          });
    },
  },
};
</script>
