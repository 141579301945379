<template>
  <v-dialog v-model="showModal" max-width="500" persistent>
    <div class="account-page">
      <div class="account-content">
        <div class="login-wrapper login-new">
          <div class="login-content user-login">
            <div class="login-userset">
              <div class="login-userheading text-center">
                <h3>{{ title }}</h3>
                <h4 class="verfy-mail-content">
                  {{ description }}
                </h4>
              </div>
              <div class="wallet-add">
                <v-otp-input
                  v-model="otpCode"
                  focus-all
                  variant="solo-filled"
                />
              </div>
              <div class="text-center mb-1">
                <small v-if="otpInvalid !== ''" class="text-danger">{{
                  otpInvalid
                }}</small>
              </div>
              <div class="text-center">
                <p class="d-flex justify-content-center gap-1">
                  Tidak menerima kode OTP ?
                  <span
                    v-if="countDownTimer !== 0"
                    class="text-primary fw-semibold"
                  >
                    {{ countDownTimer }} detik</span
                  >
                  <button
                    v-else
                    class="hover-a text-primary"
                    @click="sendOtp()"
                  >
                    Kirim Ulang
                  </button>
                </p>
              </div>
              <div class="form-login mt-4">
                <button
                  class="btn btn-login"
                  :disabled="isLoading || otpCode.length <= 5"
                  @click="validateOtp()"
                >
                  Konfirmasi
                  <div
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm text-warning ml-1"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { axiosNoAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  props: {
    openModal: {
      type: Boolean,
      Required: true,
    },
    email: {
      type: String,
      Required: true,
    },
    usage: {
      type: String,
      Required: true,
    },
    title: {
      type: String,
      Required: true,
    },
    description: {
      type: String,
      Required: true,
    },
  },
  data() {
    return {
      otpCode: "",
      isLoading: false,
      showModal: false,
      otpInvalid: "",
      intervalId: null,
      countDownTimer: 0,
    };
  },
  watch: {
    openModal(newValue) {
      this.showModal = newValue;
      this.startCountdown();
    },
  },
  beforeUnmount() {},
  methods: {
    startCountdown() {
      this.countDownTimer = 10;

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        console.log(this.countDownTimer);
        if (this.countDownTimer > 0) {
          this.countDownTimer = this.countDownTimer - 1;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    sendOtp() {
      const url = "/api/v1/auth/send-otp";
      const body = {
        email: this.email,
      };
      axiosNoAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            this.startCountdown();
          }
        })
        .catch(() => {
          this.isLoading = false;
          toast("error");
        });
    },
    validateOtp() {
      this.isLoading = true;
      const url =
        this.usage === "register"
          ? "/api/v1/auth/verify-otp"
          : "/api/v1/auth/forgot-password/verify";
      const body = {
        otp: this.otpCode,
        email: this.email,
      };
      axiosNoAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            const token = response.data.data.token;
            sessionStorage.setItem("token", token);
            this.$emit("success-validate");
            toast("success", "Success OTP verified");
          }
        })
        .catch(() => {
          this.otpInvalid = "Kode OTP tidak sesuai";
          this.otpCode = "";
          this.isLoading = false;
        });
    },
  },
};
</script>
