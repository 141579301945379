<template>
  <div class="sidebar horizontal-sidebar">
    <div id="sidebar-menu-3" class="sidebar-menu">
      <ul class="nav">
        <li class="submenu">
          <a
            href="#sidebarDashboardOne"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarDashboardOne"
            :class="[
              isDashboardOneMenuOpen ? 'active' : 'notactive',
              { subdrop: isDashboardOneMenuOpen },
            ]"
            v-on:click="isDashboardOneMenuOpen = !isDashboardOneMenuOpen"
          >
            <vue-feather type="grid"></vue-feather> <span>Main Menu</span>
            <span class="menu-arrow"></span>
          </a>
          <ul class="collapse menu-dropdown" id="sidebarDashboardOne">
            <li>
              <router-link
                :class="currentPath == '/dashboard' ? 'active' : 'notactive'"
                to="/dashboard"
                >Dashboard</router-link
              >
            </li>
          </ul>
        </li>
        <li class="submenu">
          <a
            href="#sidebarInventory"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarInventory"
            :class="[
              isInventoryMenu ? 'active' : 'notactive',
              { subdrop: isInventoryMenuOpen },
            ]"
            v-on:click="isInventoryMenuOpen = !isInventoryMenuOpen"
          >
            <img src="@/assets/img/icons/product.svg" alt="img" /><span>
              Inventory
            </span>
            <span class="menu-arrow"></span
          ></a>
          <ul class="collapse menu-dropdown" id="sidebarInventory">
            <li>
              <router-link
                :class="
                  currentPath == '/inventory/product-list'
                    ? 'active'
                    : 'notactive'
                "
                to="/inventory/product-list"
                ><span>Products</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/inventory/add-product'
                    ? 'active'
                    : 'notactive'
                "
                to="/inventory/add-product"
                ><span>Create Product</span></router-link
              >
            </li>
          </ul>
        </li>
        <li class="submenu">
          <a
            href="#sidebarSalesOne"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarSalesOne"
            :class="[
              isSalesMenuOpen ? 'active' : 'notactive',
              { subdrop: isSalesMenuOpen },
            ]"
            v-on:click="isSalesMenuOpen = !isSalesMenuOpen"
          >
            <img src="@/assets/img/icons/purchase1.svg" alt="img" /><span
              >Sales</span
            >
            <span class="menu-arrow"></span
          ></a>
          <ul class="collapse menu-dropdown" id="sidebarSalesOne">
            <li>
              <router-link
                :class="
                  currentPath == '/sales/sales-list' ? 'active' : 'notactive'
                "
                to="/sales/sales-list"
                ><span>Sales</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/sales/sales-returns' ? 'active' : 'notactive'
                "
                to="/sales/sales-returns"
                ><span>Sales Return</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="currentPath == '/sales/pos' ? 'active' : 'notactive'"
                to="/sales/pos"
                ><span>POS</span></router-link
              >
            </li>
          </ul>
        </li>
        <li class="submenu">
          <a
            href="#sidebarStockOne"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarStockOne"
            :class="[
              isStockMenuOpen ? 'active' : 'notactive',
              { subdrop: isStockMenuOpen },
            ]"
            v-on:click="isStockMenuOpen = !isStockMenuOpen"
          >
            <img src="@/assets/img/icons/purchase1.svg" alt="img" /><span
              >Stock</span
            >
            <span class="menu-arrow"></span
          ></a>
          <ul class="collapse menu-dropdown" id="sidebarStockOne">
            <li>
              <router-link
                :class="
                  currentPath == '/stock/manage-stocks' ? 'active' : 'notactive'
                "
                to="/stock/manage-stocks"
                ><span>Manage Stock</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/stock/stock-adjustment'
                    ? 'active'
                    : 'notactive'
                "
                to="/stock/stock-adjustment"
                ><span>Stock Adjustment</span></router-link
              >
            </li>
          </ul>
        </li>
        <li class="submenu">
          <a
            href="#sidebarUsers"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarUsers"
            :class="[
              isUsersMenu ? 'active' : 'notactive',
              { subdrop: isUsersMenuOpen },
            ]"
            v-on:click="isUsersMenuOpen = !isUsersMenuOpen"
          >
            <img src="@/assets/img/icons/users1.svg" alt="img" /><span
              >User Management</span
            >
            <span class="menu-arrow"></span
          ></a>
          <ul class="collapse menu-dropdown" id="sidebarUsers">
            <li>
              <router-link
                :class="
                  currentPath == '/users/users-list' ? 'active' : 'notactive'
                "
                to="/users/users-list"
                ><span>Users</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/users/roles-permissions'
                    ? 'active'
                    : 'notactive'
                "
                to="/users/roles-permissions"
                ><span>Roles & Permissions</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/users/delete-account'
                    ? 'active'
                    : 'notactive'
                "
                to="/users/delete-account"
                ><span>Delete Account Request</span></router-link
              >
            </li>
          </ul>
        </li>
        <li class="submenu">
          <a
            href="#sidebarReport"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarReport"
            :class="[
              isReportMenu ? 'active' : 'notactive',
              { subdrop: isReportMenuOpen },
            ]"
            v-on:click="isReportMenuOpen = !isReportMenuOpen"
          >
            <img src="@/assets/img/icons/printer.svg" alt="img" /><span
              >Reports</span
            >
            <span class="menu-arrow"></span
          ></a>
          <ul class="collapse menu-dropdown" id="sidebarReport">
            <li>
              <router-link
                :class="
                  currentPath == '/reports/sales-report'
                    ? 'active'
                    : 'notactive'
                "
                to="/reports/sales-report"
                ><span>Sales Report</span></router-link
              >
            </li>
            <li>
              <router-link
                :class="
                  currentPath == '/reports/purchase-report'
                    ? 'active'
                    : 'notactive'
                "
                to="/reports/purchase-report"
                ><span>Purchase report</span></router-link
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isDashboardOneMenuOpen: false,
      isInventoryMenuOpen: false,
      isSalesMenuOpen: false,
      isUsersMenuOpen: false,
      isUsersMenu: false,
      isStockMenuOpen: false,
      isReportMenuOpen: false,
      activeClass: "active",
    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    isInventoryMenu() {
      return (
        this.$route.name == "/inventory/product-list" ||
        this.$route.name == "/inventory/add-product" ||
        this.$route.name == "/inventory/expired-products" ||
        this.$route.name == "/inventory/low-stocks" ||
        this.$route.name == "/inventory/category-list" ||
        this.$route.name == "/inventory/sub-categories" ||
        this.$route.name == "/inventory/brand-list" ||
        this.$route.name == "/inventory/units" ||
        this.$route.name == "/inventory/varriant-attributes" ||
        this.$route.name == "/inventory/warranty" ||
        this.$route.name == "/inventory/barcode" ||
        this.$route.name == "/inventory/qrcode"
      );
    },
    isSalesMenu() {
      return (
        this.$route.name == "/stock/manage-stocks" ||
        this.$route.name == "/sales/sales-list" ||
        this.$route.name == "/sales/sales-returns" ||
        this.$route.name == "/sales/pos"
      );
    },
    isReportMenu() {
      return (
        this.$route.name == "/reports/sales-report" ||
        this.$route.name == "/reports/purchase-report"
      );
    },
  },
};
</script>
