import { createRouter, createWebHistory } from "vue-router";

// authentication
import Register from "@/views/pages/pages/authentication/register/pos-register.vue";
import SignIn_3 from "@/views/pages/pages/authentication/login/signin-3.vue";
import Forgot_Password_2 from "@/views/pages/pages/authentication/forgot-password/forgot-password-2.vue";
import Reset_Password_2 from "@/views/pages/pages/authentication/reset-password/reset-password-2.vue";
import SuccessTwo from "@/views/pages/pages/success-2.vue";
import Lock_Screen from "@/views/pages/pages/authentication/lock-screen.vue";

// error pages
import Under_Maintenance from "@/views/pages/pages/under-maintenance.vue";
import Error from "@/views/pages/pages/error/pos-error.vue";
import Error_404 from "@/views/pages/pages/error/error-404.vue";
import Error_500 from "@/views/pages/pages/error/error-500.vue";

// Apps
import AdminDashboard from "@/views/pages/dashboard/admin-dashboard.vue";
import Subscribe from "@/views/pages/pages/subscribe/subscribe-users.vue";
import Add_Merchant from "@/views/pages/pages/add-merchant/add-merchant.vue";

import Inventory from "@/views/pages/inventory/pos-inventory.vue";
import Add_Product from "@/views/pages/inventory/add-product.vue";
import Edit_Product from "@/views/pages/inventory/edit-product.vue";
import Product_Details from "@/views/pages/inventory/product-details.vue";
import Product_List from "@/views/pages/inventory/product-list/product-list.vue";

import Stock from "@/views/pages/stock/pos-stock.vue";
import ManageStocks from "@/views/pages/stock/manage-stocks.vue";
import StockAdjustment from "@/views/pages/stock/stock-adjustment.vue";

import Sales from "@/views/pages/sales/pos-sales.vue";
import SalesList from "@/views/pages/sales/sales-list.vue";
import SalesReturns from "@/views/pages/sales/sales-returns.vue";

import Reports from "@/views/pages/reports/pos-reports.vue";
import Sales_Report from "@/views/pages/reports/sales-report.vue";
import Purchase_Report from "@/views/pages/reports/purchase-report.vue";
import POS from "@/views/pages/sales/sales-pos.vue";

import Users from "@/views/pages/users/pos-users.vue";
import Users_List from "@/views/pages/users/users-list.vue";
import Roles_Permissions from "@/views/pages/users/roles-permissions.vue";
import Permissions from "@/views/pages/users/pos-permissions.vue";
import Delete_Account from "@/views/pages/users/delete-account.vue";
import Profile from "@/views/pages/pages/pos-profile.vue";
import Settings from "@/views/pages/settings/pos-settings.vue";
import General_Settings from "@/views/pages/settings/general-settings/general-settings.vue";
import Security_Settings from "@/views/pages/settings/general-settings/security-settings.vue";
import Notification from "@/views/pages/settings/general-settings/pos-notification.vue";
import Connected_Apps from "@/views/pages/settings/general-settings/connected-apps.vue";
import System_Settings from "@/views/pages/settings/website-settings/system-settings.vue";
import Company_Settings from "@/views/pages/settings/website-settings/company-settings.vue";
import Localization_Settings from "@/views/pages/settings/website-settings/localization-settings.vue";
import Prefixes from "@/views/pages/settings/website-settings/pos-prefixes.vue";
import Preference from "@/views/pages/settings/website-settings/pos-preference.vue";
import Appearance from "@/views/pages/settings/website-settings/pos-appearance.vue";
import Social_Authentication from "@/views/pages/settings/website-settings/social-authentication.vue";
import Language_Settings from "@/views/pages/settings/website-settings/language-settings.vue";
import Language_Settings_Web from "@/views/pages/settings/website-settings/language-settings-web.vue";
import Invoice_Settings from "@/views/pages/settings/app-settings/invoice-settings.vue";
import Printer_Settings from "@/views/pages/settings/app-settings/printer-settings.vue";
import Pos_Settings from "@/views/pages/settings/app-settings/pos-settings.vue";
import Custom_Fields from "@/views/pages/settings/app-settings/custom-fields.vue";
import Email_Settings from "@/views/pages/settings/system-settings/email-settings.vue";
import Sms_Gateway from "@/views/pages/settings/system-settings/sms-gateway.vue";
import Otp_Settings from "@/views/pages/settings/system-settings/otp-settings.vue";
import Gdpr_Settings from "@/views/pages/settings/system-settings/gdpr-settings.vue";
import Payment_Gateway_Settings from "@/views/pages/settings/financal-settings/payment-gateway-settings.vue";
import Bank_Settings_Grid from "@/views/pages/settings/financal-settings/bank-settings-grid.vue";
import Bank_Settings_List from "@/views/pages/settings/financal-settings/bank-settings-list.vue";
import Tax_Rates from "@/views/pages/settings/financal-settings/tax-rates.vue";
import Currency_Settings from "@/views/pages/settings/financal-settings/currency-settings.vue";
import Storage_Settings from "@/views/pages/settings/other-settings/storage-settings.vue";
import Ban_Ip_Address from "@/views/pages/settings/other-settings/ban-ip-address.vue";
import CheckPayment from "@/views/pages/pages/subscribe/check-payment.vue";

const profile = localStorage.getItem('profile')
const auth = localStorage.getItem('auth')

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: { requiresAuth: false }
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: SignIn_3,
  },
  {
    path: "/register",
    name: "register",
    meta: { requiresAuth: false },
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { requiresAuth: false },
    component: Forgot_Password_2,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: { requiresAuth: false },
    component: Reset_Password_2,
  },
  {
    path: "/success",
    name: "success",
    meta: { requiresAuth: false },
    component: SuccessTwo,
  },
  {
    path: "/lock-screen",
    name: "lock-screen",
    meta: { requiresAuth: false },
    component: Lock_Screen,
  },
  {
    path: "/settings",
    meta: { requiresAuth: true },
    component: Settings,
    children: [
      { path: "", redirect: "/settings/general-settings" },
      { path: "general-settings", component: General_Settings },
      { path: "security-settings", component: Security_Settings },
      { path: "notification", component: Notification },
      { path: "connected-apps", component: Connected_Apps },
      { path: "system-settings", component: System_Settings },
      { path: "company-settings", component: Company_Settings },
      { path: "localization-settings", component: Localization_Settings },
      { path: "prefixes", component: Prefixes },
      { path: "preference", component: Preference },
      { path: "appearance", component: Appearance },
      { path: "social-authentication", component: Social_Authentication },
      { path: "language-settings", component: Language_Settings },
      { path: "language-settings-web", component: Language_Settings_Web },
      { path: "invoice-settings", component: Invoice_Settings },
      { path: "printer-settings", component: Printer_Settings },
      { path: "pos-settings", component: Pos_Settings },
      { path: "custom-fields", component: Custom_Fields },
      { path: "email-settings", component: Email_Settings },
      { path: "sms-gateway", component: Sms_Gateway },
      { path: "otp-settings", component: Otp_Settings },
      { path: "gdpr-settings", component: Gdpr_Settings },
      { path: "payment-gateway-settings", component: Payment_Gateway_Settings },
      { path: "bank-settings-grid", component: Bank_Settings_Grid },
      { path: "bank-settings-list", component: Bank_Settings_List },
      { path: "tax-rates", component: Tax_Rates },
      { path: "currency-settings", component: Currency_Settings },
      { path: "storage-settings", component: Storage_Settings },
      { path: "ban-ip-address", component: Ban_Ip_Address },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    meta: { requiresAuth: true },
    component: Profile,
  },
  {
    path: "/error",
    meta: { requiresAuth: true },
    component: Error,
    children: [
      { path: "", redirect: "/error/error-404" },
      { path: "error-404", component: Error_404 },
      { path: "error-500", component: Error_500 },
      { path: "under-maintenance", component: Under_Maintenance },
    ],
  },
  {
    path: "/inventory",
    meta: { requiresAuth: true },
    component: Inventory,
    children: [
      { path: "", redirect: "/inventory/product-list" },
      { path: "product-list", component: Product_List },
      { path: "add-product", component: Add_Product },
      { path: "edit-product", component: Edit_Product },
      { path: "product-details", component: Product_Details },
    ],
  },
  {
    path: "/users",
    meta: { requiresAuth: true },
    component: Users,
    children: [
      { path: "", redirect: "/users/users-list" },
      { path: "users-list", component: Users_List },
      { path: "roles-permissions", component: Roles_Permissions },
      { path: "permissions", component: Permissions },
      { path: "delete-account", component: Delete_Account },
    ],
  },
  {
    path: "/reports",
    meta: { requiresAuth: true },
    component: Reports,
    children: [
      { path: "", redirect: "/reports/sales-report" },
      { path: "sales-report", component: Sales_Report },
      { path: "purchase-report", component: Purchase_Report },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { requiresAuth: true },
    component: AdminDashboard,
  },
  {
    path: "/subscribe",
    name: "subscribe",
    meta: { requiresAuth: true },
    component: Subscribe,
  },
  { path: "/subscribe/payment",
    name: "payment",
    meta: { requiresAuth: true },
    component: CheckPayment },
  {
    path: "/merchant",
    name: "merchant",
    meta: { requiresAuth: true },
    component: Add_Merchant,
  },
  {
    path: "/stock",
    meta: { requiresAuth: true },
    component: Stock,
    children: [
      { path: "", redirect: "/stock/manage-stocks" },
      { path: "manage-stocks", component: ManageStocks },
      { path: "stock-adjustment", component: StockAdjustment },
    ],
  },
  {
    path: "/sales",
    meta: { requiresAuth: true },
    component: Sales,
    children: [
      { path: "", redirect: "/sales/sales-list" },
      { path: "sales-list", component: SalesList },
      { path: "sales-returns", component: SalesReturns },
      { path: "pos", component: POS },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next('/error')
  }

  if (to.meta.requiresAuth) {
    if (auth === null) {
      next('/login')
    } else if (to.name === 'dashboard') {
      next()
    } else if (to.name !== 'dashboard' && to.name !== 'merchant' && profile.merchant === null) {
      next('/merchant')
    } else {
      next()
    }
  } else {
    if (auth) {
      next('/dashboard')
    } else {
      next()
    }
  }

  next();
});
