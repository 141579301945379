export const formatIDR = (amount, decimalPlaces) => {
  return amount.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
  //   example : setCookie('username', 'john_doe', 7);
};

export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    // Jika cookie dimulai dengan nama yang diinginkan
    if (cookie.indexOf(name + "=") === 0) {
      return cookie.substring(name.length + 1);
    }
  }
  //   example : getCookie('username')
  return null;
};

export const isNumber = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 48) {
    evt.preventDefault();
  }
  return true;
};

export const NoSpace = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode === 32) {
    e.preventDefault();
  }
  return true;
};

export const isAlphabet = (e) => {
  const char = String.fromCharCode(e.keyCode);
  if (/^[A-Za-z]+$/.test(char)) {
    return true;
  }
  if (/\s/.test(char)) {
    return true;
  }
  e.preventDefault();
  return false;
};
