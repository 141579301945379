import axios from "axios";
import { refreshToken } from "../services/global-url";

const auth = JSON.parse(localStorage.getItem("auth"));
let token = null;

if (auth) {
  token = auth.access_token;
}

export const axiosNoAuth = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 12000,
  headers: {},
});

export const axiosWithAuth = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 12000,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

axiosWithAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      refreshToken(error.config)
    }
    return Promise.reject(error);
  }
);
