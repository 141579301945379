import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { router } from "@/router";
import { getAuth, setAuth, setProfile } from "../utils/storage";
import { setCookie } from "../utils/libs";

export const getProfileApi = async () => {
  const url = "/api/v1/auth/profile";
  await axiosWithAuth
    .get(url)
    .then((response) => {
      setProfile(response.data.data);
      if (response.data.data.merchant === null) {
        router.push("/merchant");
      }
    })
    .catch(() => {
      toast("error");
    });
};

export const subscription = async () => {
  const url = "/api/v1/subscription";
  await axiosWithAuth
    .get(url)
    .then((response) => {
      if (response.data.meta.code === 200) {
        const expired = Math.floor((new Date(response.data.data.expired_at) - new Date())/(1000 * 60 * 60 * 24))
        setCookie('subscription', response.data.data.plan_name, expired)
      }
    })
    .catch(() => {
      toast("error");
    });
};

export const refreshToken = async (lastApi) => {
  const url = "/api/v1/auth/refresh-token";
  const body = {
    refresh_token: getAuth.refresh_token,
  };
  axiosWithAuth
    .post(url, body)
    .then((response) => {
      if (response.data.meta.code === 200) {
        setAuth(response.data.data);
        lastApi.config.headers.Authorization = `Bearer ${response.data.data.access_token}`
        setTimeout(() => {
          axiosWithAuth(lastApi);
        }, 3000);
      }
    })
    .catch(() => {
      window.location = "/lock-screen";
    });
};
