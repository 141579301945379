<template>
  <div class="account-page">
    <div class="account-content">
      <div class="login-wrapper">
        <div class="login-content">
          <Form
            @submit="submitForm"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="@/assets/img/logo/logo.svg" alt="img" />
              </div>
              <router-link to="/dashboard" class="login-logo logo-white">
                <img src="@/assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Lupa Kata Sandi</h3>
                <h4>
                  Masukkan email anda untuk kami mengirimkan kode OTP yang digunakan untuk membuat ulang kata sandi baru anda
                </h4>
              </div>
              <div class="form-login">
                <label>Email</label>
                <div class="form-addons">
                  <Field
                    name="email"
                    type="text"
                    class="form-control"
                    placeholder="Contoh : example@kasirkita.com"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <div class="emailshow text-danger" id="email"></div>
                  <img src="@/assets/img/icons/mail.svg" alt="img" />
                </div>
              </div>
              <div class="form-login">
                <button type="submit" class="btn btn-login">Konfirmasi</button>
              </div>
              <div class="signinform text-center">
                <h4>
                  Kembali ke<router-link to="/login" class="hover-a">
                    login
                  </router-link>
                </h4>
              </div>
            </div>
          </Form>
        </div>
        <div class="login-img">
          <img src="@/assets/img/authentication/forgot-02.png" alt="img" />
        </div>
      </div>
    </div>

    <otp-input
      :open-modal="showModal"
      :email="email"
      usage="forgot_password"
      title="Lupa Kata Sandi" 
      description="Masukkan kode OTP yang telah kami kirimkan ke email anda untuk membuat ulang kata sandi baru anda" 
      @success-validate="$router.push('/reset-password')"
    />
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { axiosNoAuth } from "@/plugins/axios";
import otpInput from "@/components/modal/otp-input/otpInput.vue";
import { toast } from "@/components/modal/toast-message/toastMessage";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    Field,
    otpInput,
  },
  data() {
    return {
      showModal: false,
      showPassword: false,
      email: "",
    };
  },
  methods: {
    submitForm(values) {
      this.email = values.email;
      const url = "/api/v1/auth/forgot-password";
      const body = {
        email: values.email,
      };
      axiosNoAuth
        .post(url, body)
        .then((response) => {
          if (response.data.meta.code === 200) {
            this.showModal = true;
          }
        })
        .catch(() => {
          toast("error");
        });
    },
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
    });
    return {
      schema,
    };
  },
};
</script>
