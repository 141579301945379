<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Berlangganan</h4>
          </div>
        </div>
      </div>
      <a-skeleton v-if="isLoading" active />
      <div v-else class="card table-list-card">
        <div class="card-body mt-5">
          <h2 class="text-center mb-5">Pilih Paket Kamu</h2>
          <div class="justify-content-center d-flex">
            <div
              class="btn-group btn-group-pill"
              role="group"
              aria-label="Basic example"
            >
              <button
                :class="classActive === 1 ? 'activeCustom' : 'btn-primary'"
                class="btn"
                type="button"
                @click="getList(1)"
              >
                1 Bulan
              </button>
              <button
                :class="classActive === 6 ? 'activeCustom' : 'btn-primary'"
                class="btn"
                type="button"
                @click="getList(6)"
              >
                6 Bulan
              </button>
              <button
                :class="classActive === 12 ? 'activeCustom' : 'btn-primary'"
                class="btn"
                type="button"
                @click="getList(12)"
              >
                1 Tahun
              </button>
            </div>
          </div>
        </div>
        <div class="mb-5 px-5">
          <a-tabs>
            <a-tab-pane>
              <div class="d-flex gap-5">
                <div
                  v-for="(item, idx) in listBenefit"
                  :key="idx"
                  :class="
                    selectedCard === item.id ? 'border-primary shadow-lg' : ''
                  "
                  class="border border-2 rounded-4"
                  style="width: 500px"
                  @click="selectedCard = item.id"
                >
                  <div
                    class="p-4 rounded-top-4"
                    style="background-color: #f0eef6"
                  >
                    <div class="d-flex justify-content-between">
                      <img
                        v-if="item.name === 'Lite'"
                        style="width: 50px"
                        src="@/assets/img/icon/icon-lite-plan.svg"
                        alt="lite"
                      />
                      <img
                        v-if="item.name === 'Plus'"
                        style="width: 50px"
                        src="@/assets/img/icon/icon-plus-plan.svg"
                        alt="lite"
                      />
                      <img
                        v-if="item.name === 'Premium'"
                        style="width: 50px"
                        src="@/assets/img/icon/icon-premium-plan.svg"
                        alt="lite"
                      />
                      <div
                        v-if="item.is_allowed_trial"
                        class="fw-semibold text-pos"
                      >
                        <span
                          class="px-6 py-1 fs-18 rounded-pill"
                          style="background-color: #ffffff"
                          >Uji coba 15 hari</span
                        >
                      </div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div>
                        <div class="fs-22 fw-bold my-2">
                          Paket {{ item.name }}
                        </div>
                        <div v-if="item.name === 'Lite'">
                          Mulai kecil kecilan dulu
                        </div>
                        <div v-if="item.name === 'Plus'">
                          Tambah team lebih banyak
                        </div>
                        <div v-if="item.name === 'Premium'">
                          Management toko super besar
                        </div>
                      </div>
                      <div class="text-end">
                        <div class="fs-22 fw-semibold my-2">
                          {{ formatIDR(item.price, false) }}/bln
                        </div>
                        <div class="text-pos fw-semibold">
                          hanya
                          {{ formatIDR(item.price / 30, false) }}/hari
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-4">
                    <div class="fw-bold mb-4">Benefit :</div>
                    <div>
                      <div class="d-flex mb-2">
                        <img
                          src="@/assets/img/icon/CheckCircle.svg"
                          alt="check"
                        />
                        <div class="ms-1">
                          Mengatur {{ item.limit_store }} Toko
                        </div>
                      </div>
                      <div class="d-flex mb-2">
                        <img
                          src="@/assets/img/icon/CheckCircle.svg"
                          alt="check"
                        />
                        <div class="ms-1">
                          Pantau {{ item.limit_user }} Kasir
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div class="d-flex justify-content-center gap-5 my-10">
          <button
            v-if="listBenefit.find((item) => item.is_allowed_trial)"
            class="btn btn-secondary rounded rounded-pill"
            :disabled="selectedCard !== 1"
            @click="subcribe('trial')"
          >
            Coba 15 hari gratis
          </button>
          <button
            class="btn btn-primary rounded rounded-pill"
            :disabled="selectedCard === null"
            @click="subcribe('regular')"
          >
            Berlangganan Sekarang
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatIDR } from "@/utils/libs";
import { axiosWithAuth } from "@/plugins/axios";
import { toast } from "@/components/modal/toast-message/toastMessage";
import { getListPlan, setListPlan } from "@/utils/storage";

export default {
  data() {
    return {
      listBenefit: [],
      listBenefitArray: [],
      formatIDR,
      activeKey: 1,
      isLoading: false,
      selectedCard: null,
      classActive: 1,
    };
  },
  mounted() {
    this.getListSubcribe();
  },
  methods: {
    getListSubcribe() {
      this.isLoading = true;
      if (getListPlan) {
        this.listBenefitArray = getListPlan;
        this.listBenefit = this.listBenefitArray.monthly;
        this.isLoading = false;
      } else {
        const url = "/api/v1/subscription/plan";
        axiosWithAuth
          .get(url)
          .then((response) => {
            this.isLoading = false;
            if (response.data.meta.code === 200) {
              setListPlan(response.data.data);
              this.listBenefitArray = response.data.data;
              this.listBenefit = this.listBenefitArray.monthly;
            }
          })
          .catch(() => {
            toast("error");
            this.isLoading = false;
          });
      }
    },
    getList(value) {
      this.classActive = value;
      if (value === 1)
        return (this.listBenefit = this.listBenefitArray.monthly);
      if (value === 6)
        return (this.listBenefit = this.listBenefitArray.six_monthly);
      if (value === 12)
        return (this.listBenefit = this.listBenefitArray.yearly);
    },
    subcribe(type) {
      const url = "/api/v1/subscription/subscribe";
      const body = {
        subscription_plan_id: this.selectedCard,
        subscription_type: type,
      };
      axiosWithAuth
        .post(url, body)
        .then((response) => {
          this.isLoading = false;
          if (response.data.meta.code === 200) {
            if (type === "trial") {
              toast("success", "Uji Coba Gratis selama 15 hari");
              this.$router.push("/dashboard");
            } else {
              this.$router.push("/subscribe/payment");
            }
          }
        })
        .catch(() => {
          toast("error");
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.activeCustom {
  background-color: #ffffff;
  color: #6B50A0;
  border: solid 2px #6B50A0;
}
</style>
