<template>
    <div class="account-page">
        <div class="main-wrapper">
            <div class="account-content">
                <div class="login-wrapper">
                    <div class="login-content">
                        <div class="login-userset">
                            <div class="login-userset">
                                <div class="login-logo logo-normal">
                                    <img src="@/assets/img/logo/logo.svg" alt="img">
                                </div>
                            </div>
                            <router-link to="/dashboard" class="login-logo logo-white">
                                <img src="@/assets/img/logo-white.png" alt="">
                            </router-link>
                            <div class="login-userheading text-center">
                                <img src="@/assets/img/icons/check-icon.svg" alt="Icon">
                                <h3 class="text-center">Success</h3>
                                <div class="text-center mb-4">
                                    <h4 class="fw-bold">Kata Sandi Berhasil diganti</h4>
                                </div>
                                <div class="text-center">
                                    <span class="verfy-mail-content ">
                                        Klik Lanjutkan untuk masuk ke dalam aplikasi Kasirkita dan mulai untuk menggunakan kembali
                                    </span>
                                </div>
                            </div>


                            <div class="form-login">
                                <router-link class="btn btn-login mt-0" to="/login">Kembali ke Login</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="login-img">
                        <img src="@/assets/img/authentication/step-two.png" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>